import {
  API_ENDPOINT_GET_INVOICES,
  API_ENDPOINT_GET_INVOICE_DASHBOARD,
  API_ENDPOINT_GET_INVOICE_DOWNLOAD,
  API_ENDPOINT_GET_INVOICE_DROPDOWNS,
  API_ENDPOINT_GET_INVOICE_LOGS,
  API_ENDPOINT_GET_INVOICE_WORKFLOW_STATUS,
  API_ENDPOINT_PATCH_INVOICE,
  API_ENDPOINT_POST_INVOICE_UPLOAD,
  API_ENDPOINT_PUT_INVOICE_WORKFLOW,
  API_ENDPOINT_GET_INVOICE_TEMPLATE
} from '@/config/api.config';
import { IInvoicesPageFilter } from '@/model/invoice';
import { AxiosProgressEvent } from 'axios';
import { axiosInstance } from './api.setup';
import { mapToInvoiceRawKeys } from './invoice.adapters';
import {
  IFileUploadResponseRaw,
  IInvoiceDetailsRaw,
  IInvoiceLogsRaw,
  IInvoicePatchRaw,
  IInvoiceWorkflowDecisionRaw,
  IInvoiceWorkflowRaw,
  IInvoicesPageRaw,
  IInvoicesSummaryRaw,
  IItemsSendPatchRaw
} from './invoice.types';

/**
 * GET Invoices (Paginated)
 */
export const getInvoicesPageRaw = async (filter: IInvoicesPageFilter): Promise<IInvoicesPageRaw> => {
  const params = new URLSearchParams();
  params.append('page', String(filter.page));
  params.append('size', String(filter.pageSize));
  if (filter.statuses.size) {
    params.append('invoice_status', [...filter.statuses].join(','));
  }
  if (filter.sorting.size) {
    params.append(
      'sortby',
      [...filter.sorting].map(sorting => `${mapToInvoiceRawKeys(sorting.field)}:${sorting.order}`).join(',')
    );
  }
  if (filter.searchText) {
    params.append('invoice_no', filter.searchText);
    // params.append('supplier', filter.searchText);
    // params.append('currency', filter.searchText);
    // params.append('inputter', filter.searchText);
    // params.append('requestor', filter.searchText);
    // params.append('invoice_priority', filter.searchText);
    // params.append('invoice_status', filter.searchText);
  }
  const config = {
    method: 'GET',
    url: API_ENDPOINT_GET_INVOICES + "/",
    params: params
  };
  const response = await axiosInstance.request<IInvoicesPageRaw>(config);
  return response.data;
};

/**
 * GET Invoice Details
 */
export const getInvoiceDetailsRaw = async (documentNo: string): Promise<IInvoiceDetailsRaw> => {
  const config = {
    method: 'GET',
    url: `${API_ENDPOINT_GET_INVOICES}/${documentNo}`
  };
  const response = await axiosInstance.request<IInvoiceDetailsRaw>(config);
  return response.data;
};

/**
 * PATCH Invoice
 */
export const patchInvoiceDetailsRaw = async (
  invoice: IInvoicePatchRaw,
  userName: string
): Promise<IInvoiceDetailsRaw> => {
  const config = {
    method: 'PATCH',
    url: `${API_ENDPOINT_PATCH_INVOICE}/${invoice.document_no}`,
    headers: {
      user: userName
    },
    data: JSON.stringify(invoice)
  };
  const response = await axiosInstance.request<IInvoiceDetailsRaw>(config);
  return response.data;
};

/**
 * PATCH Invoice Items
 */
export const patchItemsInvoiceRaw = async (
  invoiceItems: IItemsSendPatchRaw,
  userName: string
): Promise<IInvoiceDetailsRaw> => {
  const config = {
    method: 'PATCH',
    url: API_ENDPOINT_PATCH_INVOICE,
    headers: {
      user: userName
    },
    data: JSON.stringify(invoiceItems)
  };
  const response = await axiosInstance.request<IInvoiceDetailsRaw>(config);
  return response.data;
};

/**
 * GET Invoice Workflow Decision
 */
export const getInvoiceWorkflowDecisionRaw = async (): Promise<IInvoiceWorkflowRaw> => {
  const config = {
    method: 'GET',
    url: API_ENDPOINT_GET_INVOICE_WORKFLOW_STATUS
  };
  const response = await axiosInstance.request<IInvoiceWorkflowRaw>(config);
  return response.data;
};

/**
 * PUT Invoice Workflow Decision
 */
export const putInvoiceWorkflow = async (
  documentNo: string,
  workflowDecision: IInvoiceWorkflowDecisionRaw,
  userName: string
): Promise<any> => {
  const config = {
    method: 'PUT',
    url: `${API_ENDPOINT_GET_INVOICES}/${documentNo}/${API_ENDPOINT_PUT_INVOICE_WORKFLOW}`,
    headers: {
      user: userName
    },
    data: JSON.stringify(workflowDecision)
  };
  return await axiosInstance.request<string>(config);
};

/**
 * GET Invoices Summary
 */
export const getInvoicesSummaryRaw = async (): Promise<IInvoicesSummaryRaw> => {
  const config = {
    method: 'GET',
    url: API_ENDPOINT_GET_INVOICE_DASHBOARD
  };
  const response = await axiosInstance.request<IInvoicesSummaryRaw>(config);
  return response.data;
};

/**
 * GET Invoice Logs (History)
 */
export const getInvoiceLogsRaw = async (documentNo: string): Promise<IInvoiceLogsRaw> => {
  const config = {
    method: 'GET',
    url: `${API_ENDPOINT_GET_INVOICES}/${documentNo}/${API_ENDPOINT_GET_INVOICE_LOGS}`
  };
  const response = await axiosInstance.request<IInvoiceLogsRaw>(config);
  return response.data;
};

/**
 * POST Upload Invoices
 */
export const postUploadInvoicesRaw = async (
  filesUploaded: { file: File } | any,
  userName: string,
  signal: AbortSignal,
  progressCallback: { onUploadProgress: (progressEvent: AxiosProgressEvent) => void } | undefined
): Promise<IFileUploadResponseRaw> => {
  const config = {
    method: 'POST',
    url: API_ENDPOINT_POST_INVOICE_UPLOAD,
    headers: {
      user: userName
    },
    data: filesUploaded,
    signal,
    onUploadProgress: progressCallback?.onUploadProgress || undefined
  };

  const response = await axiosInstance.request<IFileUploadResponseRaw>(config);
  return response.data;
};

/**
 * GET Invoice PDF
 */
export const getDownloadInvoice = async (documentNo: string): Promise<any> => {
  const config = {
    method: 'GET',
    url: `${API_ENDPOINT_GET_INVOICES}/${documentNo}/${API_ENDPOINT_GET_INVOICE_DOWNLOAD}`,
    headers: {
      Accept: 'application/pdf'
    },
    responseType: 'arraybuffer' as 'json'
  };
  return await axiosInstance.request<any>(config);
};

/**
 * GET Dropdowns
 */
export const getInvoiceDropDowns = async (fields?: string): Promise<any> => {
  const config = {
    method: 'GET',
    url: API_ENDPOINT_GET_INVOICE_DROPDOWNS
  };
  return await axiosInstance.request<any>(config);
};

export const getInvoiceTemplate = async (): Promise<any> => {
  const config = {
    method: "GET",
    url: `${API_ENDPOINT_GET_INVOICES}/${API_ENDPOINT_GET_INVOICE_TEMPLATE}`,
    responseType: 'arraybuffer' as "json"
  };
  return await axiosInstance.request<any>(config);
}
