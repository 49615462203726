import IconFile from '@/assets/icons/icon-file.svg';
import { InlineBadge, Tooltip, toast } from '@/components/ui';
import { useAppDataContext, useInvoiceDashboardContext } from '@/context';
import { nextSortingUtil } from '@/hooks/useSorting';
import { Invoice, InvoiceFieldSorting, InvoicePriority } from '@/model/invoice';
import { Sorting } from '@/model/sorting';
import { downloadInvoice } from '@/services';
import * as FileManager from '@/services/file-management.service';
import { currencyFormatter, dateFormatter, emptyFormatter } from '@/utils';
import { AxiosError } from 'axios';
import { JSX, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './DashboardTable.module.css';
import { getDisplayIcon } from '@/components/modules/Common/IconDisplay';
import { AccessConfiguration } from "@/constants/accessConfiguration.constants";
//@ts-ignore
import { Table, TableFooterPaginationProps, Typography } from "@aiops/styleguide";
import { columns, DEFAULT_RES_PER_PAGE_OPTS, rowPropsConfig } from './DefaultTable.config';

export const LinkText = ({ invoiceNo, invoiceDocumentNo, isClickable }: any) => {
  const nav = useNavigate();
  return (
    <div className={styles.navigateLink}>
      <button
        data-testid="link-text-btn"
        disabled={!isClickable}
        onClick={() =>
          nav(`/invoice?id=${invoiceDocumentNo}`)}
      >
        <Typography variant="caption1c">{invoiceNo}</Typography>
      </button>
    </div>
  )
};

const DashboardTable = (): JSX.Element => {
  const {
    state: { isLoadingInvoicesTable, invoicesPage, invoicesPageFilter },
    actions: { setInvoicesPageFilter }
  } = useInvoiceDashboardContext();
  const sorting = [...invoicesPageFilter.sorting];
  const headerCells: any = {};
  const invoices = invoicesPage.data;
  const warningMsg = <p>Potential Duplicate</p>;
  const {
    state: { appPermissions },
    actions: { getUserAccessForResource }
  } = useAppDataContext();

  const [userAccess, setUserAccess] = useState<Record<string, Record<string, boolean>>>({
    accessDetailsPage: { access: false }
  });

  const sortingBy = (field: keyof Invoice): Sorting =>
    sorting.filter((fieldSorting: InvoiceFieldSorting) => fieldSorting.field === field)[0]?.order;

  const nextSorting = (field: keyof Invoice): Set<InvoiceFieldSorting> => {
    const currentSortingField = sortingBy(field);
    return currentSortingField === Sorting.Descending
      ? new Set([
        ...[...invoicesPageFilter.sorting].filter(
          (invoiceSorting: InvoiceFieldSorting) => invoiceSorting.field !== field
        )
      ])
      : new Set([
        ...[...invoicesPageFilter.sorting].filter(
          (invoiceSorting: InvoiceFieldSorting) => invoiceSorting.field !== field
        ),
        { field: field, order: nextSortingUtil(currentSortingField) }
      ]);
  };

  columns.forEach((c: any) => {
    const field = c.field;
    headerCells[field] = {
      field,
      sortField: field,
      title: field === "fileType" ? <img src={IconFile} alt="PDF Header" className={styles.headerFileIcon} /> : c.title,
      isCurrentSortColumn: sortingBy(field) !== undefined,
      sortedAscending: sortingBy(field) === Sorting.Ascending,
      onClick: () => {
        if (field !== "fileType") {
          setInvoicesPageFilter({
            ...invoicesPageFilter,
            sorting: nextSorting(field),
          });
        }
      },
      styleObj: field === "fileType" ? { maxWidth: "60px" , cursor:"default" } : undefined,
    }
  });

  /* on change in appPermissions for the user */
  useEffect(() => {
    setUserAccess({
      accessDetailsPage: getUserAccessForResource(AccessConfiguration.page.details)
    });
  }, [appPermissions]);


  const location = useLocation();
  const showToasts = (toastsToShow: [{ msg: string; variant: 'success' | 'error' }]): void => {
    toastsToShow.map(({ msg, variant }) => toast(msg, variant));
  };

  useEffect(() => {
    if (location.state) return showToasts(location.state);
    return () => { };
  }, [location]);

  useEffect(() => {
    window.history.replaceState({}, document.title);
    return () => { };
  }, [location]);

  const errorHandlerGetFile = (error: AxiosError): void => {
    if (error.code === 'ERR_NETWORK') {
      toast(error.message, 'error');
    } else if (error.code === 'ERR_BAD_RESPONSE') {
      toast('Error getting invoice file', 'error');
    }
  };

  const getInvoicePdf = (documentNo: string): void => {
    FileManager.getFile(downloadInvoice, errorHandlerGetFile)(documentNo);
  };

  const statusCalculator = (priority: InvoicePriority): JSX.Element => {
    return <p className={`${styles.priorityStatus} priority-${priority.toLowerCase()}`}>{priority}</p>
  }

  const pagination: TableFooterPaginationProps = {
    currentPage: invoicesPageFilter.page - 1,
    rowsPerPage: invoicesPageFilter.pageSize,
    rowsPerPageOptions: DEFAULT_RES_PER_PAGE_OPTS,
    totalResults: invoicesPage.total,
    onNextPage: () => {
      const maxPageNum = Math.ceil(invoicesPage.total / invoicesPageFilter.pageSize);

      setInvoicesPageFilter({
        ...invoicesPageFilter,
        page: Math.min(invoicesPageFilter.page + 1, maxPageNum)
      });
    },
    onPreviousPage: () => {
      setInvoicesPageFilter({
        ...invoicesPageFilter,
        page: Math.max(invoicesPageFilter.page - 1, 0)
      });
    },
    onSelectRowsPerPage: (r: number) => {
      setInvoicesPageFilter({
        ...invoicesPageFilter,
        pageSize: r,
        page: 1
      });
    }

  }

  const rowData = invoices.map((invoice) => {
    return {
      fileType:
        <div className={styles.fileAccessContainer}>
          <button onClick={(): void => getInvoicePdf(invoice.documentNo)} data-testid="file-button">
            {getDisplayIcon({
              iconStyle: "small",
              fileType: invoice.fileType || "",
              className: "mx-auto"
            })}
          </button>
        </div>,

      invoiceNo:
        <>
          {invoice.invoiceNo.value ? (
            <div className="flex items-center">
              <LinkText invoiceNo={invoice.invoiceNo.value} invoiceDocumentNo={invoice.documentNo} isClickable={userAccess.accessDetailsPage?.access}/>
              {invoice.isPotentiallyDuplicated && (
                <Tooltip content={warningMsg} variant="warning">
                  <div className="ml-1 pl-0.5 imageContainer">
                    <InlineBadge variant="warning" />
                  </div>
                </Tooltip>
              )}
            </div>
          ) : (
            '-'
          )}
        </>,

      supplier: emptyFormatter(invoice.supplier.value),
      invoiceDate: invoice.invoiceDate.value ? dateFormatter(invoice.invoiceDate.value) : undefined,
      currency: emptyFormatter(invoice.currency.value),
      grossAmount: invoice.grossAmount.value ? currencyFormatter(invoice.grossAmount.value) : undefined,
      submitter: emptyFormatter(invoice.submitter.value),
      requester: emptyFormatter(invoice.requester.value),
      priority: invoice.priority.value ? statusCalculator(invoice.priority.value) : <>-</>,
      status: invoice.status
    }
  })

  return (
    <div className={styles.landingPageTable}>
      <Table
        tableIsLoading={isLoadingInvoicesTable}
        columnKeys={columns.map((col) => col.field)}
        headerCells={headerCells}
        rows={rowData}
        pagination={pagination}
        rowProps={rowPropsConfig}
      />
    </div>
  );
};

export default DashboardTable;