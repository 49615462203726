import IconDownload from '@/assets/icons/icon-download.svg';
import { getInvoiceTemplate } from '@/services/api.requests';
import * as FileManager from '@/services/file-management.service';
import { Tooltip, toast } from '@/components/ui';
import './DownloadInvoiceButton.css';
import { AxiosError } from "axios";

const errorCallback = (error: AxiosError): void => {
  if (error.code === 'ERR_NETWORK') {
    toast(error.message, 'error');
  } else {
    toast('Error getting invoice template', 'error');
  }
};

const getDownloadTemplate = async (): Promise<[boolean, any]> => {
  try {
    const downloadedInvoice = await getInvoiceTemplate();
    return [false, downloadedInvoice];
  } catch (err) {
    return [true, err];
  }
};


const downloadTemplate = async (): Promise<any> => {
  await FileManager.getFile(getDownloadTemplate, errorCallback)("");
};

const DownloadInvoiceButton = (): JSX.Element => {
  return (
    <Tooltip content={"Download Template"}>
      <button
        data-testid="download-invoice-btn"
        className="download-inoice-btn"
        onClick={downloadTemplate}
      >
        <img className="download-inoice-btn-icon" src={IconDownload} alt="Download Template" />
      </button>
    </Tooltip>
  );
};

export default DownloadInvoiceButton;