import { API_ENDPOINT_BASEURL_APPID } from '@/config/api.config';
import { User } from '@/model/user';
import { getUserLogged } from '@/services';
import { setAuthToken, setBaseUrl } from '@/services/api.setup';
import { JSX, SetStateAction, useEffect, useMemo, useState  } from 'react';
import { AppDataContext, defaultUser } from './appData.context';
import { IAppDataContext, IAppDataProviderProps } from './appData.types';
// @ts-ignore
import { useAuth } from '@aiops/styleguide';

export const AppDataProvider = ({
  appId: appIdProp,
  appName,
  appPath: appPathProp,
  appSettingsPath: appSettingsPathProp,
  apiEndpoints,
  children
}: IAppDataProviderProps): JSX.Element => {
  const auth = useAuth();

  /* STATE */

  const appId = appIdProp;
  const appPath = appPathProp;
  const appSettingsPath = appSettingsPathProp;
  const apiBaseUrl = `${apiEndpoints.endpointBaseUrl}${API_ENDPOINT_BASEURL_APPID}`;
  const apiToken = auth.getIdToken();
  const [user, setUser] = useState<User>(defaultUser);
  const [appPermissions, setAppPermissions] = useState<Record<string, any>>();

  const getAppPermissions = async () => {
    return await auth.getAppPermissions(appId);
  };

  const getUserAccessForResource = (resourceType: string) : Record<string, any>=> {
    return appPermissions?.[appId];
  }

  /* ACTIONS */

  /* SIDE EFFECTS */

  setBaseUrl("https://btp-backend.dev.aiops-d.cloud/invoices");
  setAuthToken(apiToken);

  useEffect(() => {
    getUserLogged(auth).then(userLogged => setUser(userLogged));
    auth.getAppPermissions(appId).then((permissions: SetStateAction<Record<string, any>>) => {
      setAppPermissions(permissions);
    });
  }, []);

  /* CONTEXT */

  const appDataContext: IAppDataContext = useMemo(
    () => ({
      state: {
        appId,
        appName,
        appPath,
        appSettingsPath,
        apiBaseUrl,
        apiToken,
        user,
        appPermissions
      },
      actions: {
        setUser,
        getAppPermissions,
        getUserAccessForResource
      }
    }),
    [appId, appName, appPath, appSettingsPath, apiBaseUrl, apiToken, user, appPermissions]
  );

  return <AppDataContext.Provider value={appDataContext}>{children}</AppDataContext.Provider>;
};
